<template>
<div>
    <v-container fluid class="pa-0 parentDiv">
        <v-img class="bannerProducto" :src="require('@/assets/img/despensa/'+banner+'.png')" />
        <RouterLink to="/">
            <v-img class="backHome" max-width="40" :src="require('@/assets/img/btnHome.png')" />
        </RouterLink>
    </v-container>
    <v-container mt-10></v-container>
    <v-container>
        <v-img max-width="350" class="mx-auto" :src="require('@/assets/img/logos/LogoDespensa.png')" />
    </v-container>
    <v-container>
        <v-layout wrap>
            <v-flex xs12 md8 class="mx-auto">
                <v-layout wrap>
                    <v-flex xs12 md8 class="mx-auto">
                        <Cardcliente :cliente="datos" :idCliente="idCliente" :status="status" />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
    <Footer programa="Despensa" />
</div>
</template>

<script>
import Footer from "@/components/FooterPrograma.vue";
import Cardcliente from "@/components/despensa/CardClientePago.vue";

export default {
    name: "Compradespensa",
    components: {
        Footer,
        Cardcliente
    },
    data: () => ({
        valid: true,
        btnCompra: false,
        idCliente: "",
        status: "inicio",
        datos: {},
        reglas: {
            idCliente: [
                value => !!value || "Campo requerido."
            ]
        }
    }),
    mounted() {
        this.verificaToken();
    },
    methods: {
        verificaToken() {
            let token = this.$localStorage.get('token');
            if (token) {
                token = this.$jwt.decode(token);
                this.idCliente = token.data.cliente.idCliente;
                this.datos = token.data;
                this.status = token.data.cliente.statusPago;
            } else {
                this.status = "nuevo";
            }
        }
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerDespensaMovil';
                case 'sm':
                    return 'BannerDespensaMovil';
                case 'md':
                    return 'BannerDespensa';
                case 'lg':
                    return 'BannerDespensa';
                case 'xl':
                    return 'BannerDespensa';
            }
        }
    }
}
</script>
